@import "@design";

.btn-base {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.03em;
  &.v-btn {
    min-width: 40px;
  }
  &.v-btn--xs {
    $dim: 30px;
    height: $dim;
    width: $dim;
    min-width: $dim;
    min-height: $dim;
  }
}
