@import "@design";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.6s;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity 0.2s;
}
.fade-fast-enter,
.fade-fast-leave-active {
  opacity: 0;
}
.fade-fast-enter-active {
  // transition-delay: 0.3s;
}
