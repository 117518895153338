@import "@design";

@import '@design';
.display-only {
  // styles copied mostly from .v-messages__message
  margin-bottom: 0.75em;

  @media #{$mobile} {
    margin-top: 4px;
  }
  .hint {
    display: inline-block;
    width: 80px;
    padding-left: 0;
    margin-bottom: 4px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);
  }
}
