@import "@design";

.job-asset-manager-table {
  max-height: 400px;
  height: 400px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;

  .v-table__overflow {
    overflow: unset;
    table {
      tbody,
      thead {
        tr {
          td,
          th {
            padding: 0 12px;
          }
          &,
          td,
          th {
            height: 35px;
          }
        }
      }
      thead {
        position: sticky;
        left: 0px;
        top: 0;
        background: white;
        z-index: 4;
        th {
          font-weight: 900;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 100%;
          height: 1px;
          background: #e0e0e0;
        }
      }
    }
  }
}
