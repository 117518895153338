@import "@design";

.left-side {
  font-size: 1.2rem;
  a {
    color: $gray-body;
  }
}
.item-index {
  margin-right: 7px;
}
.right-side {
  margin-bottom: 0;
  .btn {
    padding: 0 0.6em;
    border: 1px solid #bbb;
    &:hover,
    &:active {
      color: white;
      border-color: transparent;
    }
    &.view-btn {
      flex-basis: calc(#{'45% - 10px'});
      margin-right: 10px;
    }
    &.new-report-btn {
      flex-basis: 55%;
    }
  }
}
