@import "@design";

.dropdown-button {
  color: white;
  text-transform: none;
}
.navbar a {
  text-decoration: none;
}
.header-subtitle {
  line-height: 14px;
  a {
    font-size: 14px;
  }
}
.header-title,
.header-subtitle {
  a {
    transition: color 0.3s ease;
    &:link,
    &:visited {
      color: white;
    }
    &:hover {
      color: lighten(black, 30%);
    }
  }
}
