@import "@design";

.asset-type-button {
  width: 150px;
  &,
  &:hover,
  &:focus {
    position: absolute;
  }
  right: 10px;
  top: 10px;
}
.job-identifier {
  left: 20px;
  top: 20px;
  position: absolute;
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(10px);
}

.slide-left-leave-from,
.slide-left-enter-to {
  opacity: 1;
}

.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.12s;
}
