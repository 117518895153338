@import "@design";

.records-list {
  list-style: none;
}
.record-item {
  width: 95%;
  max-width: 760px;
  height: 60px;
  padding: 12px 20px;
  margin: 0 auto 7px;
  font-weight: 500;
  text-align: left;
  a:link,
  a:visited {
    color: $blue;
  }
  &:hover {
    background-color: lighten($blue, 42);
  }
}
.left-side {
  font-size: 1.2rem;
}
.item-index {
  margin-right: 7px;
  color: $gray-body;
}

.search-field,
.search-submit {
  display: inline-block;
}

.input-wrap {
  align-items: center;
  padding: 5px;
  color: white;
  background-color: #565656;
  .search-field {
    margin-bottom: 0;
    &.error {
      border-bottom-color: red;
    }
  }
  .clear-btn {
    padding: 0 0.5em;
    font-size: 0.85rem;
    .material-icons {
      margin-right: 3px;
    }
  }
}

.submit-wrap {
  position: relative;
  align-items: center;
  justify-content: center;
}

.search-spinner-wrap {
  .spinner--wrap {
    margin: 0 !important;
  }
}
