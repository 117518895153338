@import "@design";

.nav-profile-btn {
  text-transform: none;
}
.v-btn.xs {
  min-width: 40px;
  padding: 0;
  margin: 0;
  ::v-deep .v-icon.v-icon--right {
    margin-left: 0;
  }
}
.v-menu__content {
  border-radius: 0;
}

.nav-dropdown-menu ::v-deep .v-list.theme--light .v-list__tile--link:hover {
  background: rgba(0, 0, 0, 0.1);
}
