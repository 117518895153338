@import "@design";

.wci-textarea.print-mode {
  text-align: left;
  white-space: break-spaces;
  .hint {
    min-width: 80px;
    padding-left: 0;
    margin: 8px 0;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);
  }
}

@media print {
  .wci-textarea.v-text-field--outline .v-input__control {
    .v-input__slot {
      padding-right: 0;
      padding-left: 0 !important;
      margin: 0 !important;
      border: none !important;
      textarea {
        font-size: 0.9em;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
