@import "@design";

.spinner--wrap {
  height: 100%;
  &.no-custom-margin {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.vue-simple-spinner-text {
  color: white;
}
