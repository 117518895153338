@import "@design";

.job-asset-editable {
  .value--wrap {
    align-self: center;
    flex-basis: 100px;
    flex-grow: 1;
    flex-shrink: 0;
    // border: 1px solid red;
  }
  .input-el {
    min-width: 100px;
    font-size: 13px;
    .v-text-field input {
      line-height: 1;
      padding: 2px 0;
    }
    .v-icon {
      font-size: 15px;
    }
  }
}

.display-value {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  min-width: 100px;
  &.empty {
    color: hsl(0, 0, 90%);
    font-style: italic;
  }
  &.local {
    color: #3c92e7;
    font-weight: bold;
  }
}
