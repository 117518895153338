@import "@design";

.v-input.v-input--checkbox {
  margin-top: 0;
}

@media print {
  .v-input.v-input--checkbox {
    display: inline-block;
  }
  .inline-checkbox {
    text-align: left;
    .v-input--selection-controls__input {
      display: none !important;
    }
  }
  .v-icon {
    margin-left: 10px;
  }
}
