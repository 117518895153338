@import "@design";

.progress--wrap {
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.v-progress-circular {
  margin: 1rem;
}
