@import "@design";

.meta--wrap {
  background-color: $gray-dark;
}

.report-meta {
  padding: 5px;
}

.manage-job-assets-btn {
  height: auto;
}

.meta-title {
  margin-bottom: 0.3em;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.1;
  text-align: left;
}
