@import "@design";

@import '@design';
.wci-picklist {
  @media #{$mobile} {
    width: 100%;
  }

  @media print {
    .v-text-field .v-input__control {
      .v-input__slot {
        padding-right: 0;
        padding-left: 0 !important;
      }
    }
  }
}
